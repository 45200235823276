











import { defineComponent, ref, useFetch, onMounted } from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import { getMetaInfo } from '~/helpers/getMetaInfo';

export default defineComponent({
  name: 'HomePage',
  setup() {
    const { addTags } = useCache();
    const storyblok = useStoryblokStore();
    const story = ref(null);
    const isFetching = ref(true);
    const pageMeta = ref(null);

    useFetch(async () => {
      await storyblok.loadHome();
      story.value = storyblok.home;
      pageMeta.value = {
        meta_title: story.value.content?.meta_title || story.value.name,
        meta_description: story.value.content?.meta_description || '',
      };
      isFetching.value = false;

      addTags([{ prefix: CacheTagPrefix.View, value: `${storyblok.spaceId}-${story.value.id}` }]);
    });

    onMounted(async () => {
      if (story?.value?.id) {
        useStoryblokBridge(story.value.id, (evStory) => {
          story.value = evStory;
        });
      }
    });

    return {
      story,
      isFetching,
      pageMeta,
    };
  },
  head() {
    return {
      script: [
        {
          hid: 'force-init-script',
          innerHTML: `window.__NUXT_SPEEDKIT_FORCE_INIT__ = true;`,
        },
      ],
      // @ts-ignore
      ...getMetaInfo(this.pageMeta),
    };
  },
});
